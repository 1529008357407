<template>
  <dash-page class="wsRounded"   >

    <!-- :: MAIN CONTENT -->

    <!-- Header and Language Switch-->


    <div class="d-flex justify-space-between px-8 py-3 pb-0 wsRounded"  >
      <div style="width: 100%">
        <!-- Task Name-->
        <wsEditorSingleLine v-model="entity.name[selectedLang]"
                            :placeholder="$t('EnterTaskName')"
                            output-format="text"
                            :editorStyle="lectureNameStyle" />
      </div>
      <!-- Language Switch-->
      <div>
        <v-chip @click=" checkLectureNameTranslation(lang); selectedLang = lang;"  v-for="(lang) in lang" :key="lang"
                class="mr-1 mt-2"
                :outlined="selectedLang !== lang"
                small >
          <!--      :color="text[lang] ? wsDARK : 'grey'" dark-->
          <h4>{{ lang }} </h4>
        </v-chip>
        <v-menu offset-y transition="slide-y-transition">

          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
                   class="noCaps mt-2 px-0"
                   :color="wsDARK"
                   text  small >
          <span>
            <v-icon small>mdi-plus</v-icon>
            {{ $t('AddTranslation') }} </span>
            </v-btn>

          </template>
          <v-list dense>
            <v-list-item-group>
              <v-list-item @click="addTranslation(lang)" v-for="lang in remainedLanguages" :key="lang">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ lang }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!-- Question Sets-->
    <v-sheet class="wsRoundedHalf pa-8 mt-6">
      <h3 >{{ $t('TestStructure') }}</h3>
      <v-simple-table  >
        <thead>
        <tr>
          <th width="20px"></th>
          <th :style="`color: ${wsDARK} !important`" >{{ $t('QuestionBank') }}</th>
          <th :style="`color: ${wsDARK} !important`" >{{ $t('Type') }}</th>
          <th :style="`color: ${wsDARK} !important`" >{{ $t('Questions') }}</th>
          <th :style="`color: ${wsDARK} !important`" >{{ $t('Points') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,i) in entity.question_sets" :key="i">
          <td class="text-no-wrap"> <h5>{{ i+1 }}.</h5> </td>

          <td>
            <div v-if="item.question_bank_id" class="d-flex align-center">
              <h5 class="font-weight-medium">
                {{  item.question_bank_name  }}
              </h5>
              <v-btn @click="showQuestionBanks(item)"
                     :outlined="!item.question_bank_id"
                     class="noCaps"
                     small
                     icon
                     text >
                <v-icon  small color="grey darken-1" >mdi-pencil</v-icon>
              </v-btn>
            </div>
            <v-btn v-else
                   @click="showQuestionBanks(item)"
                   :outlined="!item.question_bank_id"
                   class="noCaps"
                   small
                   text >
              {{ $t('ChooseQuesitonBank') }}
              <v-icon v-if="item.question_bank_id" small color="grey darken-1" class="ml-2">mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td width="120px">
            <v-btn small @click="showQuestionBanks(item)" outlined rounded :color="wsDARKER" class="noCaps" >
              {{ $t('EditQuestions') }}
            </v-btn>
          </td>
          <!-- Credits-->
          <td width="50px" align="center" >
            <v-chip small outlined :color="wsDARKER">
              <v-icon class="mr-1" x-small :color="wsDARKER">mdi-cog</v-icon>
              <h5>{{ getQuestionsQuantity(item) }}</h5>
            </v-chip>
          </td>
          <td width="50px" align="center" >
            <v-chip small outlined :color="wsDARKER">
              <v-icon small>mdi-star-three-points-outline</v-icon>
              <h5>{{ getQuestionsPointsQuantity(item) }}</h5>
            </v-chip>

          </td>


        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="5" align="right">
            <v-btn small @click="addQuestionSet()"
                   dark class="noCaps mt-3" :color="wsATTENTION">
              <v-icon small>mdi-plus</v-icon>
              {{ $t('Add') }}
            </v-btn>
          </td>
        </tr>
        </tfoot>
      </v-simple-table>
    </v-sheet>


    <template #side>
      <div class="d-flex justify-space-between align-center">
        <h4>{{ $t('Test') }}</h4>
        <div class="d-flex  align-center">
<!--          <v-btn @click="previewTest"-->
<!--                 :loading="loading"-->
<!--                 :disabled="loading"-->
<!--                 class="noCaps"-->
<!--                 small text >-->
<!--            <v-icon small>mdi-eye</v-icon>-->
<!--            {{ $t('Preview') }}-->
<!--          </v-btn>-->
          <v-btn @click="saveTest"
                 :loading="loading"
                 :disabled="loading"
                 class="noCaps"
                 small outlined >{{ $t('Save') }}</v-btn>
        </div>

      </div>

      <h5 class="mt-5">{{ $t('Settings') }}:</h5>
      <!--Task Type-->
      <div class="d-flex justify-space-between align-center ">
        <h5 class="font-weight-light">{{ $t('NoLimits') }}:</h5>
        <v-switch v-model="entity.unlimited" />
      </div>
      <!--Task Type-->
      <v-expand-transition>
        <div v-if="!entity.unlimited">
          <div class="d-flex justify-space-between align-center ">
            <h5 class="font-weight-light">{{ $t('Tries') }}:</h5>
            <v-sheet class="pa-2 ml-16" color="transparent" width="110">
              <v-text-field class=" compact-form"
                            @keydown="NUMBER_INPUT($event,entity.tries,2)"
                            v-model="entity.tries"
                            :placeholder="$t('Tries')"
                            hide-details
                            outlined
                            dense   />
            </v-sheet>
          </div>
<!--          <div class="d-flex justify-space-between align-center ">-->
<!--            <h5 class="font-weight-light">{{ $t('Duration') }}:</h5>-->
<!--            <v-sheet class="pa-2 ml-16" color="transparent" width="110">-->
<!--              <v-text-field class=" compact-form"-->
<!--                            @keydown="NUMBER_INPUT($event,entity.duration,3)"-->
<!--                            v-model="entity.duration"-->
<!--                            :placeholder="$t('Minutes')"-->
<!--                            hide-details-->
<!--                            outlined-->
<!--                            dense   />-->
<!--            </v-sheet>-->
<!--          </div>-->
        </div>
      </v-expand-transition>

<!--      <h5 class="mt-5">{{ $t('Test') }}:</h5>-->

<!--      &lt;!&ndash;Task Type&ndash;&gt;-->
<!--      <div class="d-flex justify-space-between align-center ">-->
<!--        <h5 class="font-weight-light">{{ $t('Questions') }}:</h5>-->
<!--        <h5>10</h5>-->
<!--      </div>-->
<!--      <div class="d-flex justify-space-between align-center ">-->
<!--        <h5 class="font-weight-light">{{ $t('Points') }}:</h5>-->
<!--        <h5>90</h5>-->
<!--      </div>-->

    </template>

    <!-- :: DIALOGS -->

    <!-- Qustions Set Editor Dialog -->
    <ws-dialog  :title="$t('ChooseQuestions')"
                v-model="displayDialog"
                @save="saveEntity"
                @delete="deleteItem(entityData.uuid)"
                :show-delete="dialogType === 'edit'">

      <!-- Question Bank Selector-->
      <div v-if="!entityData.question_bank_id">
        <!-- Header-->
        <div class="d-flex justify-space-between align-center">
          <h5 class="font-weight-medium"> {{questionBanks.length > 0 ? $t('QuestionBanks') : $t('NoQuestionBanksFound') }}  </h5>
          <v-btn @click="newQuestionBank" small :color="wsDARK" outlined class="noCaps">
            <v-icon small class="mr-1">mdi-file-outline</v-icon>
            {{  $t('NewQuestionBank')  }}
          </v-btn>
        </div>
        <v-data-table :items="questionBanks"
                      :headers="questionBanksHeaders"
                      class="mt-6"
                      item-key="uuid"
                      dense>

          <template  v-slot:body="{ items, select , isSelected}" >
            <tbody >
            <tr   v-for="(item,index) in items" :key="item.uuid"
                  @click="selectQuestionBank(item)"
                  :style="`background-color: ${ isSelected(item) ? wsLIGHTACCENT : ''}`"
                  style="cursor : pointer"
                  class="wsTable">

              <td width="10px" class="px-2">
                <h5 class="font-weight-medium">
                  {{ index +1}}
                </h5>
              </td>
              <td WIDTH="100%">
                <h5>{{ item.name }}</h5>
              </td>

              <td  width="10px" class="text-no-wrap pr-5" >
                <h5 class="font-weight-medium ">
                  <v-icon :color="wsDARKER" small class="mr-2">mdi-text-box-multiple-outline</v-icon>
                  {{ item.questions_count}}
                </h5>
              </td>
            </tr>
            </tbody>
          </template>

        </v-data-table>
      </div>
      <!-- Questions Selector-->
      <div v-else>
        <!-- Selected Question Bank -->
        <h5 class="font-weight-medium"> {{ $t('SelectedQuestionBank')}}  </h5>
        <v-simple-table dense class="mt-3">
          <tbody>
          <tr>
            <td>
              <div class="d-flex align-center">
                <v-icon class="pr-2" small :color="wsATTENTION">mdi-book-open-page-variant</v-icon>
                <div class="d-flex align-center">
                  <h5>{{ entityData.question_bank_name }}</h5>
                  <v-btn :to="businessDashLink('question_banks/edit/' + entityData.question_bank_id)"
                         icon  small class="noCaps">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </div>

              </div>
            </td>
            <td align="right" class="px-0">
              <v-btn @click="removeQuestionBank()" small icon><v-icon x-small>mdi-close</v-icon></v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-progress-linear :color="wsDARK" v-if="loading" indeterminate />
        <!-- Questions Selector Type-->
        <div class="d-flex justify-space-between mt-6">
          <h5 class="font-weight-medium"> {{ $t('QuestionsSelector:')}}  </h5>
          <ftSelect v-model="entityData.type"
                    :items="questionsTypes">
            <template v-slot:default="{text}">
              <div class="d-flex justify-center">
                <v-chip small outlined :color="text ? wsDARK : 'grey darken-1'">
                  <h4 class="font-weight-medium"> {{  text ? text : $t('Choose') }} </h4>
                </v-chip>
              </div>
            </template>
          </ftSelect>
        </div>

        <!-- Auto Questions Selector-->
        <div v-if="entityData.type === 'auto' && selectedQuestionBank.questions.length > 0 ">
          <h5 class="font-weight-medium mt-9 mb-3"> {{$t('ChooseAmountOfQuestions')}} : </h5>
          <v-simple-table>
            <thead>
            <tr >
              <th :style="`color: ${wsDARK}`">
                {{$t('Points')}}
              </th>
              <th :style="`color: ${wsDARK}`">{{$t('AmountOfQuestions')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,key) in questionPointsGroups" :key="key + 'points'">
              <td :style="`color : ${entityData.question_set_groups[key] > value ? wsWARNING : '' }`">
                <div class="d-flex">
                  <v-icon small :color="wsDARKER">mdi-star-three-points-outline</v-icon>
                  <h5> {{ $t('QuestionsFor') }} {{key}}  {{ $t('Points') }} <span class="font-weight-light">({{$t('Total')}}: {{value}} )</span></h5>
                </div>
              </td>
              <td align="right">
                <v-sheet   color="transparent" width="100">
                  <v-text-field class="ml-2 compact-form"
                                :error="entityData.question_set_groups[key] > value"
                                v-model="entityData.question_set_groups[key]"
                                @keydown="NUMBER_INPUT($event,entityData.question_set_groups[key],3)"
                                :placeholder="$t('Amount')"
                                hide-details
                                outlined
                                dense   />
                </v-sheet>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
        <!-- Manual Questions Selector-->
        <div v-if="entityData.type === 'select'" >
          <v-data-table :items="selectedQuestionBank.questions"
                        v-model="selectedQuestions"
                        :search="questionsSearch"
                        class="mt-6"
                        item-key="uuid"
                        dense>

            <template  v-slot:body="{ items, select , isSelected}" :customAttribute="item.uuid">
              <thead >
              <tr >
                <th class="px-0 py-2">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <v-btn @click="selectedQuestions = selectedQuestions.length < items.length ? items : []" small icon  >
                        <v-icon small :color="isSelected ? wsDARK : '' " >
                          {{ isSelected() ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                        </v-icon>
                      </v-btn>
                      <span  class="ml-8 mr-3">
                     <span v-if="selectedQuestions.length > 0">{{ $t('Selected') }} : {{ selectedQuestions.length }}</span>
                     <span v-else>{{ $t('Questions')}}</span>
                    </span>
                    </div>

                    <!--                  <wsEditMenu v-if="selectedQuestions.length > 0"-->
                    <!--                              @action="action($event)" :groupAction="true"-->
                    <!--                              :actions="groupActions" />-->
                    <span  class="mr-6"> {{ $t('Total') }} : {{ selectedQuestionBank.questions.length }} </span>
                  </div>

                </th>
              </tr>
              </thead>
              <tbody >
              <drag-select :key="'questions_multiselect_' + dragSelectKey"  @change="dragSelectQuestions($event)" attribute="customAttribute" style="cursor : pointer">
                <tr   v-for="(item,index) in items" :key="item.uuid"
                      @click="select(item, !isSelected(item) )"
                      :customAttribute="item.uuid"
                      :style="`background-color: ${ isSelected(item) ? wsLIGHTACCENT : ''}`"
                      class="wsTable">
                  <td >
                    <v-btn small icon  >
                      <v-icon small :color="isSelected ? wsDARK : '' " >
                        {{ isSelected(item) ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-btn>
                  </td>
                  <td   width="10px" class="px-2">
                    <h5 class="font-weight-medium">
                      {{ index +1}}
                    </h5>
                  </td>
                  <td WIDTH="100%">
                    <h5>{{ $t('Question') }} {{ index +1 }}</h5>
                  </td>

                  <td class="text-no-wrap">
                    <h5 v-if="item.subgroup" class="font-weight-medium mr-4">
                      <v-icon :color="wsDARKER" small class="mr-1">mdi-account-multiple</v-icon>
                      {{ item.type }}
                    </h5>
                  </td>

                  <td  width="10px" class="text-no-wrap pr-5" >
                    <h5 class="font-weight-medium ">
                      <v-icon small class="mr-2">mdi-cog</v-icon>
                      {{ item.points}}
                    </h5>
                  </td>
                </tr>
              </drag-select>
              </tbody>
            </template>

          </v-data-table>
        </div>
      </div>


    </ws-dialog>


  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import wsEditorSingleLine from "@/components/UI/wsEditorSingleLine";
import DragSelect from "drag-select-vue";

export default {
  name: "testEditor",
  props : ['uuid'],
  components : {
    wsEditorSingleLine,
    DragSelect,
  },
  data() {
    return {
      loading : false,
      entity : {
        name : {},
        question_sets : []
      },
      blankQuestionSet : {
        question_bank_id : null
      },
      selectedQuestionBank : {
        questions : []
      },

      //editor
      entityData : {},
      entityDataDefault : {},
      displayDialog : false,
      displayQuestionsDialog : false,
      dialogType : 'new',
      selectedQuestionSet : null,
      selectedQuestions : [],
      questionsSearch : '',
      questionPointsGroups : [],
      questionBanks : [],


      //technical
      //lang
      selectedLang : 'ua',
      langBase : [],
      lectureNameStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 32px;\n' +
          '  font-weight: 600;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
    }
  },
  computed : {
    questionBanksHeaders() {
      return [
        { text : ''      , value : 'number', sortable: false },
        { text : this.$t('Name') , value : 'name' },
        { text : "", value : 'questions_count', width: 10 },

      ]
    },
    questionsTypes(){
      return [
        { text : this.$t("Auto"), value : "auto" },
        { text : this.$t("Select"), value : "select" }
      ]
    },
    // lang
    langDefault() {
      return [process.env.VUE_APP_I18N_LOCALE, process.env.VUE_APP_I18N_FALLBACK_LOCALE]
    },
    lang() {
      return [...this.langDefault, ...this.langBase ]
    },
    remainedLanguages(){
      let items = this.PARAMS_languages
      items = items.filter( el=> !this.lang.includes(el) )
      return items
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_QUESTION_BANK_SHORT',
        'GET_TEST_EDIT',
        'SAVE_TEST',
        'ADD_EDIT_QUESTION_SET',
        'DELETE_QUESTION_SET',
        'ADD_EDIT_QUESTION_BANK',
        'GET_QUESTION_BANKS_FOR_TEST'
    ]),

    //test
    saveTest() {
      this.notify(this.$t('ChangesSaved'))
      this.loading = true;
      this.SAVE_TEST(this.entity).then((out)=>{
        if ( out.data ) {
          this.entity = out.data.test
        } else {
          this.notify(this.$t('NetworkError'))
        }
        this.loading = false
      }).catch(()=>{
        this.notify(this.$t('NetworkError'))
        this.loading = false })
    },
    // question set
    addQuestionSet() {
      this.entityData = {}
      this.dialogType = 'new'
      this.saveEntity()
    },
    saveEntity(closeAfterSave = true) {

      if ( !this.validateData() ) {  return false }
      this.loading = true
      this.entityData.test_id = this.uuid
      if ( this.selectedQuestions.length > 0 ) {
        this.entityData.questions = this.selectedQuestions.map(el  => [el.uuid,el.points] )
      }
      this.ADD_EDIT_QUESTION_SET(this.entityData).then((out)=>{
        if ( out.result ) {
          if( this.dialogType === "new" ) {
            this.entity.question_sets.push(out.data)
            this.flushEntity()
          } else {
            this.changeItem(out.data)
          }
          this.displayDialog = !closeAfterSave


        }
        this.loading = false
      }).catch(()=>this.loading = false)
    },
    changeItem(item) {
      var index = this.entity.question_sets
          .findIndex(el => el.uuid === item.uuid )
      this.entity.question_sets[index] = item
      this.$set(this.entity.question_sets,index,item)
    },
    deleteItem() {
      this.loading = true
      this.DELETE_QUESTION_SET(this.entityData.uuid).then((out)=>{
        if ( out.result ) {
          this.removeItem(this.entityData.uuid)
          this.displayDialog = false
        } else {
          this.notify(this.$t('EntityCannotBeDeleted'))
        }
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
    removeItem(item) {

      var index = this.entity.question_sets
          .findIndex(el => el.uuid === item )
      this.entity.question_sets.splice(index,1)
    },
    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
    },

    //question banks
    showQuestionBanks(questionSet) {
      this.dialogType = "edit"
      this.questionPointsGroups = {}
      this.selectedQuestions = []

      this.selectedQuestionSet = questionSet.uuid
      this.entityData = questionSet
      if ( this.entityData.question_set_groups instanceof Array) {
        this.entityData.question_set_groups = {}
      }
      this.displayDialog = true
      if ( this.entityData.question_bank_id) {
        this.getQuestionBanksQuestions()
      }
      else { this.getQuestionBanks() }


    },
    newQuestionBank() {
      this.loading = true
      let data =  {
        question_set_id : this.selectedQuestionSet,
        newBank : true
      }
      this.ADD_EDIT_QUESTION_BANK(data).then((out) => {
        if ( out.result ) {
          this.$router.push(this.businessDashLink('question_banks/edit/' + out.data.uuid))
        }
        this.loading = false
      }).catch( ()=> { this.laoding = false})
    },
    selectQuestionBank(questionBank) {
      this.entityData.question_bank_id = questionBank.uuid
      this.entityData.question_bank_name = questionBank.name
      this.getQuestionBanksQuestions();
    },
    removeQuestionBank() {
      this.selectedQuestionBank = {
        questions : []
      }
      this.selectedQuestions = []
      this.questionPointsGroups = {}
      this.entityData.question_bank_id = null
      this.entityData.question_set_groups = {}
      this.saveEntity(false)
      this.getQuestionBanks();
    },
    getQuestionBanks() {
      if ( this.questionBanks.length === 0 ) {
        this.loading = true;
        this.GET_QUESTION_BANKS_FOR_TEST(this.uuid).then((out)=>{
          if ( out.data ) {
            this.questionBanks = out.data.question_banks
          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    },
    getQuestionBanksQuestions() {
      this.loading = true;
      this.selectedQuestions = []
      this.questionPointsGroups = {}
      this.selectedQuestionBank = {
        questions : []
      }
      
      this.GET_QUESTION_BANK_SHORT(this.entityData.question_bank_id).then((out)=>{
        if ( out.data ) {
          this.selectedQuestionBank = out.data.question_bank
          // this.questionPointsGroups  = [...new Set(out.data.question_bank.questions.map(el => el.points))];
          if (out.data.question_bank.questions.length > 0) {
            this.questionPointsGroups = out.data.question_bank.questions.map( el=> el.points).reduce((acc, val) => {
              acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
              return acc;
            }, {});
          }
        } else {
          this.notify(this.$t('NetworkError'))
        }
        this.loading = false
      }).catch(()=>{
        this.notify(this.$t('NetworkError'))
        this.loading = false })
    },

    // test
    previewTest() {
      this.$router.push(this.businessDashLink('dashboard/tests/preview/' + this.uuid) )
    },

    //technical
    getQuestionsQuantity(questionsSet) {
      let quantity = 0;
      if ( questionsSet.type === 'auto' ) {
        Object.keys(questionsSet.question_set_groups).forEach((points)=> {
          let amount = parseInt(questionsSet.question_set_groups[points]) ? parseInt(questionsSet.question_set_groups[points]) : 0
          quantity += amount
        })
      }
      else  if ( questionsSet.type === 'select' ) {
        if ( questionsSet.questions ) {
          quantity = questionsSet.questions.length
        }
      }
      return quantity;
    },
    getQuestionsPointsQuantity(questionsSet) {
      let quantity = 0;
      if ( questionsSet.type === 'auto' ) {
        Object.keys(questionsSet.question_set_groups).forEach((points)=> {
          let amount = parseInt(questionsSet.question_set_groups[points]) ? parseInt(questionsSet.question_set_groups[points]) : 0
              quantity += amount * parseInt(points)
        })
      }
      else  if ( questionsSet.type === 'select' ) {
        if ( questionsSet.questions ) {
          if ( questionsSet.questions.length ) {
            let points = questionsSet.questions.map( el => el[1])
            quantity = points.reduce((partialSum, a) => partialSum + a, 0);
          }
        }
      }
      return quantity;
    },
    validateData() {
      let validation = true
      if (this.entityData.type === 'auto') {
        Object.keys(this.entityData.question_set_groups).forEach((points) => {
          if ( this.entityData.question_set_groups[points] < 0 ) {
            this.notify(this.$t('WrongQuantity'));
            validation = false
          }
          if ( this.entityData.question_set_groups[points] > this.questionPointsGroups[points] ) {
            this.notify(this.$t('NotEnoughQuestionsQuantity'))
            validation = false
          }
        })

      }
      return validation
    },

    addTranslation(lang) {
      this.checkLectureNameTranslation(lang)
      this.langBase.push(lang)
    },
    getTranslation(translations) {
      return translations[this.selectedLang] ? translations[this.selectedLang] :  this.$t('NoTranslation')
    },
    checkLectureNameTranslation(lang) {

      if ( !this.entity.name[lang] ) {
        this.entity.name[lang]='';
        this.entity = JSON.parse(JSON.stringify(this.entity))
      }

    },
    dragSelectQuestions($event) {
      if ( $event.length > 1 ) {
        let selectedItems = this.selectedQuestionBank.questions.filter( el => $event.includes(el.uuid))
        if ( !this.SHIFT_DOWN) {
          this.selectedQuestions = []
          this.selectedQuestions =  selectedItems
        } else {
          let selectedIds = this.selectedQuestions.map( el => el.uuid )
          selectedItems = selectedItems.filter( el => !selectedIds.includes(el.uuid) )
          this.selectedQuestions = this.selectedQuestions.concat(selectedItems)
        }
      }
    },

    initPage() {
      if ( this.uuid ) {
        this.loading = true;
        this.GET_TEST_EDIT(this.uuid).then((out)=>{
          if ( out.data ) {
            this.entity = out.data.test
            this.$store.state.tests.store_selectedTestId = out.data.test.uuid
            if ( out.data.test.name ) {
              this.$store.state.tests.store_selectedTestName = out.data.test.name[this.$i18n.locale]
            }

          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    }
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>